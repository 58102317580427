
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #252525;
  overflow: hidden;
  touch-action: none;
-ms-touch-action: none;
}
.alert{
  display: none;
}
@media (max-width: 1023px) and (orientation:landscape) {
  .homeBody {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: #7d0c0c;
    z-index: 999999999;
  }
  .homeRows3{
    display:none !important;
  }
  .homeRows{
    display:none !important;
  }
  .navRow1Mob{
    display:none !important;
  }
  .alert{
    position: absolute;
    display: flex;
    bottom: 50vh;
    left: 15vw;
    color: #fff;
    z-index: 9999999999;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #181818;
  overflow: hidden;
}

.background span {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 45;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: #cd2323;
  top: 26%;
  left: 41%;
  animation-duration: 39s;
  animation-delay: -14s;
  transform-origin: 1vw 6vh;
  box-shadow: 40vmin 0 5.1056226507809335vmin currentColor;
}
.background span:nth-child(1) {
  color: #7d0c0c;
  top: 58%;
  left: 68%;
  animation-duration: 23s;
  animation-delay: -48s;
  transform-origin: 25vw 8vh;
  box-shadow: -40vmin 0 5.1792978574094946vmin currentColor;
}
.background span:nth-child(2) {
  color: #a80000;
  top: 57%;
  left: 68%;
  animation-duration: 28s;
  animation-delay: -50s;
  transform-origin: 21vw 3vh;
  box-shadow: 40vmin 0 5.911213819679863vmin currentColor;
}
.background span:nth-child(3) {
  color: #7d0c0c;
  top: 13%;
  left: 72%;
  animation-duration: 44s;
  animation-delay: -32s;
  transform-origin: 18vw -5vh;
  box-shadow: 40vmin 0 5.267195217332574vmin currentColor;
}
.background span:nth-child(4) {
  color: #cd2323;
  top: 35%;
  left: 95%;
  animation-duration: 10s;
  animation-delay: -47s;
  transform-origin: -9vw -7vh;
  box-shadow: 40vmin 0 5.439055966857969vmin currentColor;
}
.background span:nth-child(5) {
  color: #a80000;
  top: 32%;
  left: 70%;
  animation-duration: 53s;
  animation-delay: -34s;
  transform-origin: 12vw -5vh;
  box-shadow: 40vmin 0 5.218991503657921vmin currentColor;
}
.background span:nth-child(6) {
  color: #7d0c0c;
  top: 89%;
  left: 69%;
  animation-duration: 26s;
  animation-delay: -49s;
  transform-origin: -3vw 24vh;
  box-shadow: 40vmin 0 5.741393822192757vmin currentColor;
}
.background span:nth-child(7) {
  color: #a80000;
  top: 58%;
  left: 68%;
  animation-duration: 55s;
  animation-delay: -36s;
  transform-origin: -24vw -19vh;
  box-shadow: 40vmin 0 5.27993706991973vmin currentColor;
}
.background span:nth-child(8) {
  color: #cd2323;
  top: 98%;
  left: 94%;
  animation-duration: 52s;
  animation-delay: -31s;
  transform-origin: -15vw -10vh;
  box-shadow: 40vmin 0 5.999549887829081vmin currentColor;
}
.background span:nth-child(9) {
  color: #cd2323;
  top: 86%;
  left: 43%;
  animation-duration: 55s;
  animation-delay: -38s;
  transform-origin: -19vw -16vh;
  box-shadow: 40vmin 0 5.269225890468416vmin currentColor;
}
.background span:nth-child(10) {
  color: #cd2323;
  top: 13%;
  left: 47%;
  animation-duration: 33s;
  animation-delay: -21s;
  transform-origin: 3vw 11vh;
  box-shadow: 40vmin 0 5.770630000323885vmin currentColor;
}
.background span:nth-child(11) {
  color: #a80000;
  top: 93%;
  left: 28%;
  animation-duration: 22s;
  animation-delay: -50s;
  transform-origin: 22vw -20vh;
  box-shadow: 40vmin 0 5.825531977133989vmin currentColor;
}
.background span:nth-child(12) {
  color: #cd2323;
  top: 69%;
  left: 95%;
  animation-duration: 49s;
  animation-delay: -23s;
  transform-origin: -24vw -21vh;
  box-shadow: 40vmin 0 5.15820684805526vmin currentColor;
}
.background span:nth-child(13) {
  color: #a80000;
  top: 36%;
  left: 98%;
  animation-duration: 48s;
  animation-delay: -44s;
  transform-origin: 13vw 22vh;
  box-shadow: 40vmin 0 5.730444620110281vmin currentColor;
}
.background span:nth-child(14) {
  color: #cd2323;
  top: 26%;
  left: 75%;
  animation-duration: 39s;
  animation-delay: -22s;
  transform-origin: 23vw -22vh;
  box-shadow: 40vmin 0 5.671303788006325vmin currentColor;
}
.background span:nth-child(15) {
  color: #a80000;
  top: 46%;
  left: 4%;
  animation-duration: 30s;
  animation-delay: -37s;
  transform-origin: 0vw -14vh;
  box-shadow: -40vmin 0 5.162549154373185vmin currentColor;
}
.background span:nth-child(16) {
  color: #7d0c0c;
  top: 12%;
  left: 88%;
  animation-duration: 35s;
  animation-delay: -14s;
  transform-origin: -1vw 4vh;
  box-shadow: -40vmin 0 5.535501763576478vmin currentColor;
}
.background span:nth-child(17) {
  color: #cd2323;
  top: 40%;
  left: 45%;
  animation-duration: 19s;
  animation-delay: -23s;
  transform-origin: -14vw -20vh;
  box-shadow: -40vmin 0 5.568791000687067vmin currentColor;
}
.background span:nth-child(18) {
  color: #a80000;
  top: 31%;
  left: 95%;
  animation-duration: 16s;
  animation-delay: -38s;
  transform-origin: 6vw 6vh;
  box-shadow: -40vmin 0 5.741755336265507vmin currentColor;
}
.background span:nth-child(19) {
  color: #a80000;
  top: 16%;
  left: 79%;
  animation-duration: 13s;
  animation-delay: -3s;
  transform-origin: -5vw 23vh;
  box-shadow: -40vmin 0 5.384056682229649vmin currentColor;
}

/*Ceravan pc and Tablet*/
@media (min-width: 1024px) {
  .cardIcons2{
    display: none;
  }
  .navColumn{
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    justify-content: center;
    z-index: 99;
    margin-top: 20px;
    margin-left: 2vw;
  }
  .logo{
    position:fixed; 
        width:6vw; 
        z-Index:66;
        top: 0;
        margin-top: 5px;
  }

  .mainTable {
    border-radius: 2vw;
    background-color: rgb(44, 44, 43);
    opacity: 0.702;
    box-shadow: -5.26px 10.786px 11.52px 4.48px rgba(0, 0, 0, 0.59);
    position: fixed;
    width: 90vw;
    height: 90vh;
    left: 50%;
    margin-left: -45vw;
    top: 50%;
    margin-top: -45vh;
    z-index: 5;
  }
/* Nav*/
  .navRow1 {
    border-radius: 10vw;
    background-color: rgb(0, 0, 0);
    opacity: 0.502;
    position: fixed;
    width: 4vw;
    height: 90vh;
    left: 50%;
    margin-left: -45vw;
    top: 50%;
    margin-top: -45vh;
    z-index: 6;
  }
  .navButtonPlaceHolder{
    position: fixed;
    height: 90vh;
    left: 50%;
    margin-left: -44.25vw;
    top: 50%;
    margin-top: -45vh;
    z-index: 7;
  }
  .placeHolderTop{
    position: fixed;
    margin-top: 2vh;
    z-index: 9;

  }
  .placeHolderMid{
    position: absolute;
    height: 90vh;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 8;

  }
  .placeHolderBottom{
    position: fixed;
    bottom: 50%;
    margin-bottom: -45vh;
    padding-bottom: 2vh;
    z-index: 9;
  }
  .navButton {
    display: flex;
    justify-content: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(255, 255, 255);
    border-radius: 50%;
    background-color: #e30110c0;
    width: 2.5vw;
    height: 2.5vw;
    z-index: 8;
  }
  .navIcons{
    display: flex;
    align-items: center;
    font-size: 1.5vw;
    color:rgb(255, 255, 255);
  }
  .navIcons2{
    color: #e30110 !important;
  }
  .navIcons3{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 2.5vw;
    margin-top: 1vh;
    color: #e30110 !important;
  }
  .navButtonMid{
    margin-bottom: 4vh;
    background-color:rgb(255, 255, 255);
  }
}

/*Loader*/
.Loader{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
}
.LoaderLogo{
  width: 80vw;
  height: 80vh;
}

/* Nav*/

/*Ceravan pc and Tablet*/
/*Phone*/
@media (max-width: 1023px) {
  .navButtonPlaceHolder{
    display: none;
  }
  .navColumn{
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    justify-content: center;
    z-index: 99;
    margin-top: 20px;
  }
  .logo{
    position: fixed;
    top: 0;
    margin-top: 5px;
  }
  .logoMobileMargin{
    margin-left: 10vw;
  }
  .logoD{
    height: 12vh;
    width: 37vw;
  }

  .navRow1 {
    display: none;
  }
.cardIcons2{
  font-size: 4vh;
  color:rgba(227, 0, 15, 1);
}
.bottomNavMob{
  position: fixed;
  bottom: 0;
  height: 8vh;
  width: 90vw;
  left: 50%;
  margin-left: -45vw;
  margin-bottom: 1vh;
  color: #8a0009;
  background-color: rgb(20, 20, 20);
  opacity: 0.802;
  border-radius: 64px;
  box-shadow: 0 4px 20px rgb(31, 31, 31);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 0.5px solid rgba(227, 0, 15, 1);
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-evenly;
  padding-top: 3vh;
  -webkit-tap-highlight-color: transparent;
  z-index: 9999;
}
}


